import React from 'react';
import { graphql } from 'gatsby';
// import { Query } from 'react-apollo';
// import gql from 'graphql-tag';
import logger from 'utils/logger';
import Fade from 'react-reveal/Fade';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { Menu } from 'components/Navigation';
import Layout from 'components/Layout';
import SEO from 'components/seo';

import { Container, Row } from 'components/GridSystem';
import { BusinessFunction, SectionTitle } from 'components/Text';
// import { CapabilityCard } from 'components/Cards';

import {
  CoverCapability,
  // Capabilities,
  CapabilityBody,
  ServiceArea,
  ApproachHighlight,
  BoldStatement,
  HeroRow
} from 'components/Sections';

import { CallToActionPreFooter } from 'components/CallToActions';

class Page extends React.Component {
  render () {
    const {
      data,
      location
    } = this.props;
    logger.gql(data);
    logger.template(__filename, '>', 'render()');
    const siteTitle = data.site.siteMetadata.title;
    const {
      title,
      excerpt,
      heading,
      boldStatement,
      description,
      serviceAreas,
      featuredImage,
      applicationAreas
    } = data.contentfulCapability;

    logger.templateEnd();
    logger.debug(`excerpt`, excerpt);
    const seoProps = {
      title,
      description: description && description.childMdx.rawBody
    };
    return (
      <Layout location={location} title={siteTitle}>
        <SEO {... seoProps} />
        <Menu colorScheme={`dark`} />
        <Fade>
          <CoverCapability
            bgColor={`gray-800`}
            title={title}
            background={{
              image: featuredImage && featuredImage.cover.fixed.src
            }}
            content={excerpt && ( excerpt.childMdx.body )}
          />
        </Fade>
        <article>
          {boldStatement && (
            <BoldStatement
              label={`Discover`}
              content={boldStatement.childMdx.rawBody}
            />
          )}
          {description && (
            description.childMdx && (
              <CapabilityBody
                content={description}
                serviceAreas={serviceAreas}
              />
            )
          )}
          {/*
          <HeroRow
            background={{
              image: `https://blind.com/wp-content/uploads/2016/09/05A.jpg`
            }}
          />
          <Container>
            <SectionTitle title={`Business Functions`} />
          </Container>
          { serviceAreas && serviceAreas.map((node, i) => {
            return (
              <ServiceArea
                key={node.id}
                label={node.title}
                bgColor={`gray-${node.color === `Light` ? `100` : `800`}`}
                border={i !== serviceAreas.length - 1}
                right={node.align === `Right`}
                {...node}
              >
                <p>
                  We can help you reinvent the way you deliver and pilot your services to be more beneficial, accessible and desirable for your users, as well as more efficient and sustainable for your organization — increasing productivity, commercial value and customer satisfaction
                </p>
              </ServiceArea>
            );
          })}
          <Container>
            <SectionTitle title={`What is your next big move?`} border />
            <Row>
              { applicationAreas && (
                applicationAreas.length > 0 && applicationAreas.map((node, i) => {
                  return (
                    <BusinessFunction
                      key={node.id}
                      title={node.title}
                      content={
                        node.description &&
                          node.description.childMdx.rawBody
                      }
                      border={i < applicationAreas.length - 2}
                    />
                  );
                })
              )}
            </Row>
          </Container>
          */}
        </article>
        <CallToActionPreFooter
          bgColor={`gray-700`}
          title={`Have a challenge you’d like us to explore?`}
          callToAction={`Get in touch!`}
          uri={``}
          to={`/contact`}
          utm={{
            campaign: `t`
          }}
        />
      </Layout>
    );
  }
}

export default Page;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulCapability( slug: { eq: $slug } ){
      ... Capability
    }
  }
`;
